const t = function(){
fetch('http://192.168.15.212:6005/server/ai/chat?key=nbgz2024', {
    method:"POST",
    body:JSON.stringify({
        "content":"你好，请帮我写出圆周率的200位",
        "stream":true
    }),headers:{
        'Content-Type':'application/text'
    }}).then(response => {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const reader = response.body.getReader();  // 获取流读取器
    const decoder = new TextDecoder();  // 创建解码器
    const ReadStream = ()=>{
        reader.read().then(({done,value})=>{
            if(done){
                console.log('end')
                return
            }
            const decodedText = decoder.decode(value, { stream: true });
            console.log('chunk:',decodedText)
       
            ReadStream()
        })
        
    }
    

   ReadStream()
})
.catch(e => {
    console.error(e)
});
}
t()